.slick-prev{
    z-index: 99999;
    left: 2% !important;
}
.slick-next {
    z-index: 99999;
    right: 2% !important;
}

.slick-prev-bags{
    position: absolute;
    left: 0;
    margin-right: auto;
    top: 50%;
    padding-left: 2rem;
    z-index: 99999;
}
.slick-next-bags{
    position: absolute;
    right: 0;
    margin-left: auto;
    top: 50%;
    padding-right: 2rem;
    z-index: 99999;
}

.slick-prev:before{
    font-size: 1.875rem;
    color: #000000;
    display: none;
}
.slick-next:before {
    font-size: 1.875rem;
    color: #000000;
    display: none;
}
.slick-dots li button:before {
    color: white !important;
    font-size: 16px !important;
  }
  
  .slick-dots li.slick-active button:before {
    color: black !important; 
  }