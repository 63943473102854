@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

h1 {
    font-family: 'JosefinSans-BoldItalic', sans-serif;
}

h2 {
    font-family: 'ArchivoBlack-Regular', sans-serif;
}

h3 {
    font-family: 'JosefinSans-BoldItalic', sans-serif;
}

h4 {
    font-family: 'ArchivoBlack-Regular', sans-serif;
}
html {
    scroll-behavior: smooth;
  }

  .scale--js {
    display: inline-block;
    transform-origin: 50% 0;
    -webkit-font-smoothing: antialiased;
    transform: translate3d( 0, 0, 0);
  }